import React from "react"
import PropTypes from "prop-types"
import Layout from "./layout"

const Accordion = ({ work, link_desc }) => {
  const { title, description, link } = work
  return (
    <li className="accordion">
        <a className="uk-accordion-title" href={title}>
          {title}
        </a>
        <div className="uk-accordion-content container">
          {description}
          <a href={link} target="_blank" rel="noreferrer" aria-label={title} className="accordion__button">
            <button className="uk-button uk-button-default">{link_desc}</button>
          </a>
        </div>
    </li>
  )
}

const Works = ({ location, i18n }) => {
  return (
    <Layout location={location}>
        <h1 className="uk-text-center uk-text-left@l">{i18n.description}</h1>
      
      <div className="container">
        <ul data-uk-accordion="multiple: true">
          {i18n.works.map(work => (
            <Accordion key={work.title} work={work} {...i18n} />
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Works

Works.propTypes = {
  i18n: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
