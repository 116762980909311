export const works = [
    {
        title: 'Work 1',
        description: 'Work 1 Description',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Work 2',
        description: 'Work 2 Description',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Work 3',
        description: 'Work 2 Description',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Work 4',
        description: 'Work 2 Description',
        link: 'https://vue-mail-example.surge.sh/'
    }
]

export const works_tr = [
    {
        title: 'Çalışma 1',
        description: 'Çalışma 1 Açıklama',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Çalışma 2',
        description: 'Çalışma 2 Açıklama',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Çalışma 3',
        description: 'Çalışma 2 Açıklama',
        link: 'https://vue-mail-example.surge.sh/'
    },
    {
        title: 'Çalışma 4',
        description: 'Çalışma 2 Açıklama',
        link: 'https://vue-mail-example.surge.sh/'
    }
]