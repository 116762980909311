import React from 'react';
import Works from '../../components/works';
import { works } from "../../data/works"

const i18n = {
    description: (
        <p>
            Works
        </p>
    ),
    works,
    link_desc: "See Example"
};

export default (props) => <Works i18n={i18n} {...props} />;
